<template>
  <div class="home-wrapper" @userChange="loadUser()">
    <div id="signed-out-wrapper" v-if="this.user == null">
      <pre id="disclaimer">
there could be a few reasons you're on this site.
this website acts as a 3 day dead man's switch.

1. i'm dead and the switch went off
2. i'm stupid and forgot to validate i'm alive and the switch went off
3. code error and the switch went off

let's hope it's not 2 or 3.</pre>
      <div id="login-button" @click="login()">log in with discord</div>
      <!-- <div id="login-button" @click="login(true)">log in with discord localhost</div> -->
    </div>
    <div id="signed-in-wrapper" v-else>
      <h1 id="user-name">hey {{ user.name }}</h1>
      <h2 v-if="userData" id="welcome-message">{{ userData.welcomeMessage }}</h2>
      <div id="message-button" @click="accessMessage()">
        <span id="message-button-text">your message from me</span> <span id="message-heart">&lt;3</span>
        <div id="button-note" @click.stop="num++">
          this button should always take you to your note provided you're logged in through discord.
          the notes are auth protected, so refreshing or navigating manually to your note will error. 
        </div>
      </div>
      <div id="sign-out-button" @click="signOut()">
        sign out
      </div>
    </div>
  </div>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAGUQ2x1rF7fPiYYrQXPCzrsytHmY5BWEQ",
  authDomain: "mydms-3c309.firebaseapp.com",
  projectId: "mydms-3c309",
  storageBucket: "mydms-3c309.appspot.com",
  messagingSenderId: "409683839612",
  appId: "1:409683839612:web:a072965b422980806717f9",
  measurementId: "G-9YNKGECK7C"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth();

const db = getFirestore(app);

export default {
  name: "HomeView",
  data () {
    return {
      discordAuthUrl: "https://discord.com/oauth2/authorize?client_id=1263922022480613417&response_type=code&redirect_uri=https%3A%2F%2Fimightbedeadlolrip.com%2F&scope=identify",
      discordAuthLocal: "https://discord.com/oauth2/authorize?client_id=1263922022480613417&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2F&scope=identify",
      user: null,
      userData: null,
      token: null,
      num: 0
    }
  },
  methods: {
    login (localhost) {
      if (localhost) window.location.href = this.discordAuthLocal;
      else window.location.href = this.discordAuthUrl;
    },
    loadUser () {
      const user = auth.currentUser;
      getDoc(doc(db, 'aliases', `${user.uid.replace('discord:','')}`)).then(s => {
        if (s.exists()) {
          this.user = s.data();
          this.user.uid = user.uid;
          console.log(this.user);
          console.log('logged in');
          getDoc(doc(db, user.uid.replace('discord:',''), 'metadata')).then(s => {
            if (s.exists()) {
              this.userData = s.data();
            } else {
              this.userData = null;
            }
          });
        } else {
          this.user = null
        }
      });
    },
    loginToken (token) {
      signInWithCustomToken(auth, token)
    },
    accessMessage () {
      const user = auth.currentUser

      const page = window.location.href.split('?')[0] + `protected/${user.uid.replace('discord:','')}`;
      
      const f = document.createElement('form');
      f.action = page;
      f.method = 'post';

      const i = document.createElement('input');
      i.name = 'uid';
      i.value = user.uid;

      f.appendChild(i)
      document.body.appendChild(f);
      f.submit()
    },
    signOut () {
      signOut(auth);
      this.user = null;
      this.userData = null;
    }
  },
  mounted () {
    if (this.$route.query.code) {
      fetch(this.$route.path + `auth/discord?code=${this.$route.query.code}`).then(res => res.json()).then(data => {
        console.log(data.token);
        this.loginToken(data.token);
      })
    }
    onAuthStateChanged(auth, user => {
      if (user) {
        this.loadUser();
      } else {
        console.log('logged out');
      }
    })
  }
}
</script>

<style scoped>
#signed-out-wrapper, #signed-in-wrapper {
  width: 100svw;
  height: 100svh;
  position: relative;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 90, 90, 0.668);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

#login-button {
  padding: 1rem;
  background-color: rgba(115, 138, 219);
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

#welcome-message {
  position: absolute;
  top: 3rem;
  padding: 2rem;
}

#user-name {
  position: absolute;
  top: .3rem;
  left: 1.3rem;
}

#sign-out-button {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  background-color: rgba(115, 138, 219);
  cursor: pointer;
  border-radius: 5px;
  padding: 1rem;
}

#message-button {
  padding: 1rem;
  background-color: rgba(230, 86, 211, 0.668);
  cursor: pointer;
  border-radius: 5px;
  transition: all .2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
}

#message-heart {
  opacity: 0;
  transform: rotate(0deg);
  width: 1rem;
}

#message-button-text {
  margin-right: -1rem;
  padding-right: 0px;
  transition: all .2s ease;
}

#button-note {
  position: absolute;
  top: 5rem;
  padding: .5rem;
  max-width: calc(100vw - 1rem);
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none !important;
  text-align: center;
}

#button-note:hover {
  cursor: default !important;
}

#message-button:hover:not(:has(div:hover)) {
  background-color: rgba(230, 86, 211, 1);
}

#message-button:hover:not(:has(div:hover)) #message-heart {
  opacity: 1 !important;
  transform: rotate(-90deg) !important;
  transition: all .2s ease !important;
}

#message-button:hover:not(:has(div:hover)) #message-button-text {
  margin-right: 0px !important;
  padding-right: .5rem !important;
}

#disclaimer {
  padding: 1rem;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  max-width: calc(100vw - 2rem);
  white-space: pre-wrap;
}
</style>