import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MadelineView from '@/views/MadelineView.vue'
import WoemyView from '@/views/WoemyView.vue'
import TarynView from '@/views/TarynView.vue'
import EmView from '@/views/EmView.vue'
import ParkerView from '@/views/ParkerView.vue'
import KuroView from '@/views/KuroView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/protected/natsayuki',
    name: 'natsayuki',
    component: MadelineView
  },
  {
    path: '/protected/woemy',
    name: 'woemy',
    component: WoemyView
  },
  {
    path: '/protected/tarynbubs',
    name: 'tarynbubs',
    component: TarynView
  },
  {
    path: '/protected/emcord',
    name: 'emcord',
    component: EmView
  },
  {
    path: '/protected/darkimposter',
    name: 'darkimposter',
    component: ParkerView
  },
  {
    path: '/protected/kuroguro503',
    name: 'kuroguro503',
    component: KuroView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
