<template>
    <div id="wrapper">
        <div id="bg-cover"></div>
        <img id="gengar" src="/assets/gengar.png" />
        <h1>em</h1>
        <pre id="em-message" @scroll.passive="darken()">
i’m not the best with words, and sitting here trying to think 
of what my last ones to you should be is almost impossible. 
i don’t think any amount of time will allow me to put into 
words how thankful i am you’re my friend.

you gave me so many opportunities to better my situation, 
and instead i messed everything up.

you’re an amazing friend and i’m really sorry for messing everything 
up. you’re one of two people, the other being taryn, who i feel i 
can be myself around. as of writing this i still don’t know what 
being myself means because i never felt i had an identity growing 
up, but maybe if my suicide fails i’ll be able to find out T~T. 
you’ve always felt so reliable, and i’m sorry for taking 
advantage of that and pushing it too far. i always enjoy playing 
games with you or talking. it always seems like you’re so insightful 
on the most random things. your house is the place i’ve felt the 
safest/at home. you have such a calming and assuring presence which 
i’ve always appreciated. thank you for being an amazing friend. 

you’re one of the most caring people i’ve ever met. the two times 
i’ve been to your house you’ve been the most amazing host. even 
though you plan tons of fun stuff, i’d be content with just sitting 
around with you ‘cause i enjoy your presence that much :]! you’re 
always so understanding too, on the most recent trip when i had a 
panic attack and was out of commision for half the day, you made 
sure i was okay and set up even when you were going to be out biking. 
and that’s just one example. i know i’ve said it before, and sadly 
it’s still true, but i don’t know why you’ve stuck around with me. 
i guess i focused a lot on that, when honestly it shouldn’t matter. 
the fact is that you did, and that’s all i should’ve needed. 

you said you’d been in a similar situation to mine, and i feel like 
you offered me quite literally as much as you possibly could, but 
i let my worthless view of myself get in the way. i know i said it 
at the time, but the generosity you offered me was so thoughtful and 
appreciated. if i’d listened to you once, maybe you wouldn’t be reading 
this. 

one time i messaged you and asked if friends normally made out and 
said they loved each other. you said probably not the making out, but 
love is normal. i had a previous friend mess up the word love for me. 
i know it sounds stupid, but it’s always felt wrong coming from my 
mouth or receiving it from someone else. that being said, 
i love you guys &lt;3

i know this note is probably all over the place, and i feel like
there is so much more i want to say to you, but my brain is barely
functioning.

i know it’s a lot of me to ask something of you after all i’ve done. 
the following people have notes and if you could let them know, 
i’d really appreciate it :) i’ll update this list with additional 
people as i add more notes, but i don’t know how many will be 
done when/if this switch goes off.

taryn(tarynbubs)</pre>
    </div>
</template>

<script>
export default {
    name: "EmView",
    methods: {
        darken () {
            const message = document.getElementById('em-message');
            const scrollPos = message.scrollTop;
            const scrollSize = message.scrollHeight;
            const offset = message.offsetHeight

            const bgCover = document.getElementById('bg-cover');
            const opacity = scrollPos / (scrollSize - offset);
            bgCover.style.opacity = opacity;
        }
    }
}
</script>

<style scoped>
#gengar {
    position: absolute;
    top: -370px;
    transform: scale(.2);

}

#wrapper {
    width: 100svw;
    height: 100svh;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(242,138,248);
}

#bg-cover {
    transition: all .2s linear;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100svw;
    height: 100svh;
    background-color: black;
    opacity: 0;
}

#em-message {
    margin-top: 150px;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    max-width: 100vw;
    background-color: rgba(255, 255, 255, .3);
    padding: 1rem;
    border-radius: 5px;
    color: white;
    white-space: pre-wrap;
    z-index: 2;
}
</style>