<template>
  <router-view/>
</template>

<style>
* {
  font-family: monospace, monospace;
}

#app {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100svw;
  height: 100svh;
  overflow: auto;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
