<template>
    <div id="wrapper">
        <h1>taryn</h1>
        <img id="dog" src="/assets/rainbowdogcrop.gif" />
        <pre id="taryn-message">
i am not quite sure where to even start with a note for you.
i know the feeling probably isn't mutual, but i consider you and em my best friends ever :).


there's so much stuff i want to apologize for, but i don't know if leaving a note with
a bunch of apologies will leave a good mark. so instead, i'll say thanks! thank you
for being an amazing friend, i don't know what it is about you guys,
but you and em are the first people in my entire life i feel like i can be myself around.


this isn't a note about you and em though, it's about you. again, i can't find a reason
for this feeling, but you're the comfiest person i know. i don't use the word vibes much
but i guess that might be an apt descriptor haha.


in your bios there always seems to be something about being incredibly cool and awesome.
i think you're the coolest person :]. i always enjoy hearing about what other people find
interesting or fun, but for some reason for you, i wouldn't just enjoy it, i'd look forward to
it. i know i super suck at tetris, but even if i don't understand what you're talking about,
i always enjoy listening to you talk about it or play. when you were in the most recent tournament
at the time of writing this, i sat on the twitch page refreshing the channel for 20+ minutes
not wanting to miss a second of it. i don't know if you appreciated the cheering in the chat
or not so i apologize if it was embarrassing :'). you’re also crazy smart and funny,
which i really admire. it’s almost impossible for me to feel pride in anything i do, 
so it kinda came as a surprise when i felt proud of you for graduating. watching my friends 
succeed is one of the few things i’ve found that consistently brings me joy.


i think the reason why i look forward to talking to you is because,
not only do i feel i can really be myself, but there's been so many moments where i feel
like we shared the same braincell i couldn't recount them all. i remember it as far back
as around when we first met. we stayed up for like over a day or something because we both
had the same sleep schedule. that was the month where twenty one pilots was my top artist
because i'd just join your spotify session whenever we were up late.


i still don't know what to call it, but because of that i've always felt extra comfy around you
'cause you just got everything i said without explanation. recently, hearing about your and em's 
days was the highlight of my days. though with em being so busy most of the time it was just you lol. 
i started watching steven universe so i'd have an extra excuse to talk to you because i didn't know 
if asking how work/training went every day was depressing. you also have a super comforting
presence in person. getting high and watching random stuff you put on are some of if not my 
favorite memories.


i've never connected with someone more than you, and i'm not quite sure what that says
about you (or me T^T), but i don't think i could ask for a better friend. i want to apologize
for being an awful friend. i know i was very clingy to you guys, and on top of all the other
awful stuff i put you through, i really feel like i failed you as a friend. i don't know
if you think i failed as a friend, but my brain loves jumping to the worst conclusions.
that's why i'd always ask if i was being annoying, bothersome, or a burden. without
validation from you that we were friends, i'd start slipping and assuming the worst.
you and em are the only two people in my life where i felt like i needed validation
to assume we were friends because i really look up to both of you, and i guess i
just feel out of my league friend wise. like i don't deserve to be your friends.
even though i’m writing this under the pretense i’m dead, i’ve been working
very hard in therapy on myself so i'll be able to be the friend i want to be.


you really mean a lot to me, and i still don’t understand what you, em, and everyone 
else sees in me to stay my friend, but it literally means the world to me. i’m sorry
for messing it all up.


sorry for this note probably being all over the place. i’ve never written like this before
and it does not come naturally. plus with talking i’m extra awkward, get nervous, trip over 
my words, and embarrass myself. so it seems almost fitting for my last words to be something 
i’m consistently fluent in: javascript B). i feel like there’s so much more i want to say, 
but my brain is so foggy i could barely write all this out >.>


i know it's a lot of me to ask something of you, but
please don't share this note with my parents. i don't think they'd be able to handle hearing that i
felt i couldn't be myself around them. i don't mind if you share with em or other friends
though, but that's up to you ofcourse :)!</pre>
    </div>
</template>

<script>
export default {
    name: 'TarynView'
}
</script>

<style>
#wrapper {
    width: 100svw;
    height: 100svh;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(249,212,221,255);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

#dog {
    position: fixed;
    right: -130px;
    top: 90px;
    animation: dog-walk 13s infinite;
    animation-timing-function: linear;
    z-index: 1;
}

@media screen and (max-device-width: 1000px) {
    #dog {
        animation: dog-walk 7s infinite !important;
        animation-timing-function: linear !important;

    }
}

@keyframes dog-walk {
    from {
        right: -130px;
    }
    to {
        right: calc(100vw + 100px);
    }
}

#taryn-message {
    margin-top: 150px;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    max-width: 100vw;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 5px;
    white-space: pre-wrap;
}
</style>