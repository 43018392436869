<template>
    <div id="wrapper">
        <h3>you think i'd let you see your note early</h3>
        <h1>:P</h1>
    </div>
</template>

<script>
export default {
    name: 'KuroView'
}
</script>

<style scoped>
#wrapper {
    width: 100svw;
    height: 100svh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
</style>