<template>
    <div>
        bleep bloop placeholder put parker's note here
    </div>
</template>

<script>
export default {
    name: 'ParkerView'
}
</script>