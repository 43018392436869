<template>
    <div>maddie</div>
    <div class="view-button" @click="accessMessage('woemy')">
        woemy
    </div>
    <div class="view-button" @click="accessMessage('tarynbubs')">
        taryn
    </div>
    <div class="view-button" @click="accessMessage('emcord')">
        em
    </div>
    <div class="view-button" @click="accessMessage('darkimposter')">
        parker
    </div>
    <div class="view-button" @click="accessMessage('kuroguro503')">
        kuro
    </div>
</template>

<script>
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAGUQ2x1rF7fPiYYrQXPCzrsytHmY5BWEQ",
    authDomain: "mydms-3c309.firebaseapp.com",
    projectId: "mydms-3c309",
    storageBucket: "mydms-3c309.appspot.com",
    messagingSenderId: "409683839612",
    appId: "1:409683839612:web:a072965b422980806717f9",
    measurementId: "G-9YNKGECK7C"
};

initializeApp(firebaseConfig);

const auth = getAuth();

export default {
    name: 'MadelineView',
    data () {
        return {

        }
    },
    methods: {
        accessMessage (person) {
            const user = auth.currentUser

            const page = window.location.origin + `/protected/${person}`;
            
            const f = document.createElement('form');
            f.action = page;
            f.method = 'post';

            const i = document.createElement('input');
            i.name = 'uid';
            i.value = user.uid;

            f.appendChild(i)
            document.body.appendChild(f);
            f.submit()
        },
    }
}
</script>

<style scoped>
.view-button {
    padding: 1rem;
    background-color: blueviolet;
}
</style>